import { Redirect, Link } from "react-router-dom";
import Cookies from "js-cookie";
import { Meta, Breadcrumbs, Svg } from "modules/layout";
import { useIntl } from "react-intl";
import { AppRoute } from "const";

export const FormularOdeslan: React.FC = () => {
  const { formatMessage, locale } = useIntl();
  const intl = useIntl();

  function localizeRouteKey(path: string) {
    return `/${locale}` + formatMessage({ id: path });
  }

  const cookieValue = Cookies.get("formSubmitted");
  if(cookieValue !== "1") {
    return <Redirect to={localizeRouteKey(AppRoute["Home"])} />;
  }

  const breadcrumbs = [
    { label: intl.formatMessage({ id: "formularodeslan.title" }), link: localizeRouteKey(AppRoute["FormularOdeslan"]) }
  ];

  return (
    <>
      <Meta clas="body--clip" title={intl.formatMessage({ id: "formularodeslan.title" })} description={intl.formatMessage({ id: "formularodeslan.description" })} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className="block padding">
        <div className="container">
          <div className="row margin double">
            <div className="col-md-6 mb sm">
              <Svg src="formular-odeslan" id="11" />
            </div>
            <div className="col-md-6 align-self-center">
              <div className="md-padding pr-md-0">
                <h1 className="block__title mb"><span className="color color--green">{intl.formatMessage({ id: "formularodeslan.nadpis1" })}<br className="hidden-sm" /> {intl.formatMessage({ id: "formularodeslan.nadpis2" })}</span></h1>
                <p className="block__text mb">{intl.formatMessage({ id: "formularodeslan.text" })}<br /><br />{intl.formatMessage({ id: "common.vratte" })}</p>
                <Link className="button button--blue" to={localizeRouteKey(AppRoute["Home"])} title={intl.formatMessage({ id: "common.uvod" })}>{intl.formatMessage({ id: "common.uvod" })}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
