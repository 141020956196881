import { LanguageStrings } from "./base-strings";

export const en: LanguageStrings = {
  /** Routes */
  "routes.home": "/",
  "routes.vyroba": "/manufacture",
  "routes.produkty": "/products",
  "routes.historie": "/history",
  "routes.kontakt": "/contact",
  "routes.formularodeslan": "/form-sent",
  "routes.ochranaosobnichudaju": "/protection-personal-data",

  /** Common */
  "common.lang": "en",
  "common.poptavka": "Inquiry",
  "common.poptat": "Inquire us",
  "common.zjistit": "Find out more",
  "common.dozvedet": "Learn more",
  "common.vytvorila": "Developed by",
  "common.agentura": "the Digital agency",
  "common.vratte": "Please return to the home page.",
  "common.uvod": "Homepage",

  /** Pictogram */
  "pictogram.nadpis1": "Our products",
  "pictogram.nadpis2": "Customized production of plastics",
  "pictogram.text": "We can create anything from plastic in bulk for you! With our team of experienced professionals and state-of-the-art technology, we are ready to realize your projects and needs.",
  "pictogram.produkty": "Our products",
  "pictogram.button": "I have an order",

  /** Homepage */
  "home.menu": "Home",
  "home.title": "Plastic moulding",
  "home.description": "Thermoplastic injection moulding, rotational moulding, thermoplastic plate moulding.",
  "home.nadpis": "If you are looking for a reliable partner for the production of plastic products, you have come to the right place.",
  "home.nachazime": "We are located on the outskirts of Děčín in Jílové",
  "home.vyroba1": "We inject",
  "home.vyroba2": "thermoplastics",
  "home.vyroba3": "on modern injection",
  "home.vyroba4": "moulding machines",
  "home.vyroba5": "We use the Zhafir brand with clamping forces from 40 to 130 tons. Currently we have 15 injection molding machines.",
  "home.historie1": "With a tradition since 1946, we specialise",
  "home.historie2": "in moulding plastics",
  "home.historie3": "We have modern and progressive technologies at our disposal.",
  "home.partneri": "Our major partners",
  "home.projekt": "The Pastor & syn project{br}is co-funded by the European Union",
  "home.stahnout": "Download PDF",

  /** Výroba */
  "vyroba.menu": "Manufacture",
  "vyroba.title": "Manufacture of plastics",
  "vyroba.description": "We inject all types of thermoplastics, focus on custom tooling, manufacture tanks up to 600 litres and process thermoplastic plates.",
  "vyroba.nadpis1": "Plastic",
  "vyroba.nadpis2": "injection moulding",
  "vyroba.text1": "We use the most modern and progressive technologies in the injection moulding of all types of thermoplastics. We work on 15 Zhafir electric injection moulding machines with clamping force from 60 to 120 tons.",
  "vyroba.nadpis3": "Tool",
  "vyroba.nadpis4": "making",
  "vyroba.text2": "We provide a complete service from the design of the moulding, mould construction to the actual physical implementation of the injection mould production. Of course, we also service and maintain our customers' moulds. We also focus on custom tooling production. Provides design consultancy in the field of injection moulds. Our goal is to increase efficiency in the production of plastic parts.",
  "vyroba.nadpis5": "Rotary",
  "vyroba.nadpis6": "melting",
  "vyroba.text3": "In this modern technology we are able to produce tanks with a capacity of up to 600 litres.",
  "vyroba.nadpis7": "Welding",
  "vyroba.nadpis8": "and plate",
  "vyroba.nadpis9": "forming",
  "vyroba.text4": "We mainly process PP, PE and PVC plates for septic tanks, swimming pools and other tanks according to customer needs.",

  /** Produkty */
  "produkty.menu": "Products",
  "produkty.title": "Our plastic products",
  "produkty.description": "We use modern and progressive plastic moulding technologies in the production of our products.",
  "produkty.text": "We are open to discussing any specific requirement and would be happy to meet with you to get to know your needs better and find the optimal solution together.",

  /** Historie */
  "historie.menu": "History",
  "historie.title": "History of plastic processing",
  "historie.description": "The history of plastic processing has a really long tradition in our country, in 1991 the company was privatized.",
  "historie.nadpis": "We have been here since 1946 and have a rich company history.",
  "historie.text": "If you are looking for a reliable partner for the production of plastic products, you have come to the right place.",

  /** Kontakt */
  "kontakt.menu": "Contact",
  "kontakt.title": "Contacts Pastor & syn, s r. o.",
  "kontakt.description": "Contacts Pastor & syn, s r. o. Jílové.",
  "kontakt.trasa": "Route",
  "kontakt.nadpis": "Your reliable partner in plastics processing.",
  "kontakt.podnadpis": "Contact us if you are looking for a reliable partner and a guarantee of quality in the production of plastic products.",
  "kontakt.ip": "Your IP address will be sent to Google Maps",
  "kontakt.zobrazit": "Show map",
  "kontakt.najit": "Find on the map",
  "kontakt.adresa": "Address",
  "kontakt.telefon": "Phone",
  "kontakt.email": "Email",
  "kontakt.ico": "ID",
  "kontakt.dic": "TAX ID",
  "kontakt.ucet": "Account",
  "kontakt.osoby": "Contact persons",
  "kontakt.pozice1": "Managing Director{br}Director",
  "kontakt.pozice2": "Managing Director{br}Finance and HR Director",
  "kontakt.pozice3": "Production and Sales Director",
  "kontakt.formular": "Inquiry form",
  "kontakt.zanechte": "Please leave us a message, our specialist will deal with it as soon as possible.",
  "kontakt.jmeno": "Name",
  "chyba.jmeno": "Please fill in your name",
  "kontakt.prijmeni": "Surname",
  "chyba.prijmeni": "Please fill in your surname",
  "chyba.telefon": "Please fill in your phone number",
  "chyba.platnytelefon": "Please fill in a valid phone number",
  "chyba.email": "Please fill in your email",
  "chyba.platnyemail": "Please fill in a valid email",
  "kontakt.text": "Text",
  "chyba.text": "Please fill in the text of the message",
  "kontakt.souhlas": "I agree to",
  "kontakt.zpracovani": "the processing of personal data",
  "chyba.gdpr": "Consent to the processing of personal data is required",
  "kontakt.odeslat": "Submit",

  /** Formulář odeslán */
  "formularodeslan.title": "Form sent",
  "formularodeslan.description": "Thermoplastic injection moulding, rotational moulding, thermoplastic plate moulding.",
  "formularodeslan.nadpis1": "Form",
  "formularodeslan.nadpis2": "sent",
  "formularodeslan.text": "The form has been successfully submitted, we will contact you as soon as possible.",

  /** Ochrana osobních údajů */
  "ochranaosobnichudaju.title": "Protection of personal data",
  "ochranaosobnichudaju.description": "Protection of personal data of clients of Pastor & syn, s.r.o.",
  "ochranaosobnichudaju.text1": "Protection of personal data of clients of {company}",
  "ochranaosobnichudaju.text2": "Effective date: May 10, 2016",
  "ochranaosobnichudaju.text3": "Scope and purpose of collection and processing of personal data:",
  "ochranaosobnichudaju.text4": "The company {company} with registered office at Modrá 44, 407 02 Jílové and identification number 27348652, obtains and processes your personal data for the following reasons:",
  "ochranaosobnichudaju.text5": "proper provision of the services we offer",
  "ochranaosobnichudaju.text6": "the opportunity to answer any questions or requests",
  "ochranaosobnichudaju.text7": "understanding and clarifying your needs",
  "ochranaosobnichudaju.text8": "proper keeping of our internal records",
  "ochranaosobnichudaju.text9": "For this purpose, we collect your personal data in the range of: name, surname, e-mail address and telephone number. We store this data on the basis of your consent. When protecting your personal data, we follow Act No. 101/2000 Sb. on the protection of personal data.",
  "ochranaosobnichudaju.text10": "Provision of personal data by clients",
  "ochranaosobnichudaju.text11": "When visiting our website, you can provide us with your personal information by filling out an online inquiry. We will ask you to provide contact information (name, surname, e-mail address and telephone number) and consent to the processing of your personal data. The client agrees that the said personal data be included in the electronic database of {company} and that they be collected and processed in accordance with Act No. 101/2000 Sb. on the protection of personal data, as amended and REGULATION (EU) 2016/679 OF THE EUROPEAN PARLIAMENT AND OF THE COUNCIL of April 27, 2016, on the protection of individuals with regard to the processing of personal data and on the free movement of such data. If you do not wish us to collect personal data about you in the future, you can revoke your consent at any time by sending an e-mail to {email}.",
  "ochranaosobnichudaju.text12": "Access to your personal data",
  "ochranaosobnichudaju.text13": "You have the right to ask us to communicate the amount of personal data we collect about you. If you are interested, you can request a copy of your personal data by e-mail at {email}.{br}You have the right to request the deletion of your personal data at any time at the e-mail address {email}.",
  "ochranaosobnichudaju.text14": "Information in regard to third parties",
  "ochranaosobnichudaju.text15": "We will not provide your personal information to third parties for use in marketing their products or services without your consent.",
  "ochranaosobnichudaju.text16": "Method of personal data protection",
  "ochranaosobnichudaju.text17": "By visiting this website, you give us your consent to the collection and processing of your personal data in accordance with the above rules. We have secured the website in an appropriate manner so that there is no unauthorized communication, use, alteration or destruction of the personal data you provide to us through the site. Shall you have questions about the security of our website, send them by e-mail to {email}.",
  "ochranaosobnichudaju.text18": "Updating your data",
  "ochranaosobnichudaju.text19": "You have the opportunity to check, change or request the deletion of your personal data by sending an e-mail to {email}.",
  "ochranaosobnichudaju.text20": "Transfer of data to other countries",
  "ochranaosobnichudaju.text21": "The company {company} does not transfer personal data to other countries.",
  "ochranaosobnichudaju.text22": "Protection of personal data provided by children",
  "ochranaosobnichudaju.text23": "This site is intended for people who have reached the age of 18. Our company does not knowingly collect personal data from any person under the age of 18. If it happens that we have unintentionally received personal data from a site visitor who has not yet reached the age of 18, we will delete this data from our records. If you are under the age of 18, you may view our website, but you may not provide us with personal information. It is not possible for you to register on the site.",
  "ochranaosobnichudaju.text24": "Update of our internal regulation on personal data protection",
  "ochranaosobnichudaju.text25": "We will update this internal regulation in accordance to the change in legal regulations and the offer of our services. We will publish its amended version here. You can find the effective date of this regulation at the top of this page.",
  "ochranaosobnichudaju.text26": "Consent to the processing of personal data",
  "ochranaosobnichudaju.text27": "I confirm that by providing my personal data to {company} I give my consent to the processing of my personal data for an indefinite period for the purposes that result from the offer form.",
  "ochranaosobnichudaju.text28": "I further certify that I have been duly informed:",
  "ochranaosobnichudaju.text29": "about the scope and purpose of the personal data processed",
  "ochranaosobnichudaju.text30": "about my unconditional right to withdraw this consent at any time, as well as about the right to access and change my personal data",
  "ochranaosobnichudaju.text31": "about my right to request information about the processing of my personal data at any time",
  "ochranaosobnichudaju.text32": "Passively provided information",
  "ochranaosobnichudaju.text33": "When navigating the website, certain information may be passively collected (i.e. obtained without being actively provided by you) using various technologies (such as cookies, javascript measurement systems, especially Google Analytics, and standard logging of your HTTP requests on the part server).",
  "ochranaosobnichudaju.text34": "Your internet browser automatically passes some of the listed anonymous information to these pages, e.g., the URL of the web portal or search engine you just left (so called Referrer URL), your IP address, the version of the browser and the operating system that your computer is currently using, the default language settings, or information about your browser add-ons. This site requires the storage of cookies in your browser for its operation. You can set it to inform you when a cookie is sent, or reject or disable cookies altogether, however certain parts of this website may not work properly without cookies.",

  /** 404 */
  "error.title": "Page not found",
  "error.description": "Thermoplastic injection moulding, rotational moulding, thermoplastic plate forming.",
  "error.nadpis1": "Page",
  "error.nadpis2": "not found",
  "error.text": "Page not found."
};
