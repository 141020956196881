import { LanguageStrings } from "./base-strings";

export const de: LanguageStrings = {
  /** Routes */
  "routes.home": "/",
  "routes.vyroba": "/herstellung",
  "routes.produkty": "/produkte",
  "routes.historie": "/geschichte",
  "routes.kontakt": "/kontakt",
  "routes.formularodeslan": "/formular-gesendet",
  "routes.ochranaosobnichudaju": "/schutz-personenbezogenen-daten",

  /** Common */
  "common.lang": "de",
  "common.poptavka": "Anfrage",
  "common.poptat": "Fragen Sie uns",
  "common.zjistit": "Weitere Informationen",
  "common.dozvedet": "Mehr erfahren",
  "common.vytvorila": "Erstellt durch",
  "common.agentura": "die Digitalagentur",
  "common.vratte": "Bitte kehren Sie zur Startseite zurück.",
  "common.uvod": "Startseite",

  /** Pictogram */
  "pictogram.nadpis1": "Unsere Produkte",
  "pictogram.nadpis2": "Maßgeschneiderte Produktion von Kunststoffen",
  "pictogram.text": "Wir können alles aus Kunststoff in großen Mengen für Sie herstellen! Mit unserem Team aus erfahrenen Fachleuten und modernster Technologie sind wir bereit, Ihre Projekte und Bedürfnisse zu realisieren.",
  "pictogram.produkty": "Unsere Produkte",
  "pictogram.button": "Ich habe einen Auftrag",

  /** Homepage */
  "home.menu": "Startseite",
  "home.title": "Kunststoffspritzguss",
  "home.description": "Thermoplastisches Spritzgießen, Rotationsgießen, thermoplastisches Plattengießen.",
  "home.nadpis": "Wenn Sie auf der Suche nach einem zuverlässigen Partner für die Herstellung von Kunststoffprodukten sind, sind Sie bei uns genau richtig.",
  "home.nachazime": "Wir befinden uns am Rande von Děčín in Jílové",
  "home.vyroba1": "Wir spritzen",
  "home.vyroba2": "Thermoplaste",
  "home.vyroba3": "auf modernen",
  "home.vyroba4": "Spritzgießmaschinen",
  "home.vyroba5": "Wir verwenden die Marke Zhafir mit Schließkräften von 40 bis 130 Tonnen. Derzeit verfügen wir über 15 Spritzgießmaschinen.",
  "home.historie1": "Mit einer Tradition seit 1946 sind wir",
  "home.historie2": "auf das Formen von Kunststoffen spezialisiert.",
  "home.historie3": "Wir verfügen über moderne und fortschrittliche Technologien.",
  "home.partneri": "Unsere wichtigsten Partner",
  "home.projekt": "Das Projekt Pastor & syn{br}wird von der Europäischen Union kofinanziert",
  "home.stahnout": "PDF herunterladen",

  /** Výroba */
  "vyroba.menu": "Herstellung",
  "vyroba.title": "Herstellung von Kunststoffen",
  "vyroba.description": "Wir spritzen alle Arten von Thermoplasten, konzentrieren uns auf die Herstellung kundenspezifischer Werkzeuge, fertigen Tanks bis zu 600 Litern und verarbeiten thermoplastische Platten.",
  "vyroba.nadpis1": "Kunststoff",
  "vyroba.nadpis2": "Spritzguss",
  "vyroba.text1": "Wir verwenden die modernsten und fortschrittlichsten Technologien für das Spritzgießen aller Arten von Thermoplasten. Wir arbeiten auf 15 elektrischen Zhafir-Spritzgießmaschinen mit einer Schließkraft von 60 bis 120 Tonnen.",
  "vyroba.nadpis3": "Werkzeug",
  "vyroba.nadpis4": "bau",
  "vyroba.text2": "Wir bieten einen Komplettservice vom Entwurf der Gussform über die Konstruktion der Gussform bis hin zur eigentlichen physischen Umsetzung der Spritzgussformproduktion. Selbstverständlich übernehmen wir auch die Wartung und Instandhaltung der Formen unserer Kunden. Wir konzentrieren uns auch auf die Herstellung von kundenspezifischen Werkzeugen. Wir bieten Konstruktionsberatung im Bereich der Spritzgussformen an. Unser Ziel ist es, die Effizienz bei der Herstellung von Kunststoffteilen zu steigern.",
  "vyroba.nadpis5": "Rotierendes",
  "vyroba.nadpis6": "Schmelzen",
  "vyroba.text3": "Mit dieser modernen Technologie sind wir in der Lage, Tanks mit einem Fassungsvermögen von bis zu 600 Litern herzustellen.",
  "vyroba.nadpis7": "Schweißen",
  "vyroba.nadpis8": "und Blech",
  "vyroba.nadpis9": "bearbeitung",
  "vyroba.text4": "Wir verarbeiten hauptsächlich PP-, PE- und PVC-Platten für Klärgruben, Schwimmbäder und andere Behälter je nach Kundenwunsch.",

  /** Produkty */
  "produkty.menu": "Produkte",
  "produkty.title": "Unsere Kunststoffprodukte",
  "produkty.description": "Bei der Herstellung unserer Produkte setzen wir moderne und fortschrittliche Kunststoffspritzgusstechnologien ein.",
  "produkty.text": "Wir sind offen für jede spezifische Anforderung und würden uns freuen, uns mit Ihnen zu treffen, um Ihre Bedürfnisse besser kennen zu lernen und gemeinsam die optimale Lösung zu finden.",

  /** Historie */
  "historie.menu": "Geschichte",
  "historie.title": "Geschichte der Kunststoffverarbeitung",
  "historie.description": "Die Geschichte der Kunststoffverarbeitung hat in unserem Land eine sehr lange Tradition, 1991 wurde das Unternehmen privatisiert.",
  "historie.nadpis": "Wir sind seit 1946 vor Ort und können auf eine lange Firmengeschichte zurückblicken.",
  "historie.text": "Wenn Sie auf der Suche nach einem zuverlässigen Partner für die Herstellung von Kunststoffprodukten sind, sind Sie bei uns genau richtig.",

  /** Kontakt */
  "kontakt.menu": "Kontakt",
  "kontakt.title": "Kontakte Pastor & syn, s r. o.",
  "kontakt.description": "Kontakte Pastor & syn, s r. o. Jílové.",
  "kontakt.trasa": "Route",
  "kontakt.nadpis": "Ihr zuverlässiger Partner in der Kunststoffverarbeitung.",
  "kontakt.podnadpis": "Wenden Sie sich an uns, wenn Sie einen zuverlässigen Partner und eine Qualitätsgarantie für die Herstellung von Kunststoffprodukten suchen.",
  "kontakt.ip": "Ihre IP-Adresse wird an Google Maps gesendet",
  "kontakt.zobrazit": "Karte anzeigen",
  "kontakt.najit": "Auf der Karte finden",
  "kontakt.adresa": "Adresse",
  "kontakt.telefon": "Telefon",
  "kontakt.email": "E-mail",
  "kontakt.ico": "ID",
  "kontakt.dic": "STEUER-ID",
  "kontakt.ucet": "Konto",
  "kontakt.osoby": "Kontaktpersonen",
  "kontakt.pozice1": "Geschäftsführender Direktor{br}Direktor",
  "kontakt.pozice2": "Geschäftsführender Direktor{br}Finanz- und Personaldirektor",
  "kontakt.pozice3": "Direktor für Produktion und Vertrieb",
  "kontakt.formular": "Anfrageformular",
  "kontakt.zanechte": "Bitte hinterlassen Sie uns eine Nachricht, unser Spezialist wird sich so schnell wie möglich darum kümmern.",
  "kontakt.jmeno": "Name",
  "chyba.jmeno": "Bitte tragen Sie Ihren Namen ein",
  "kontakt.prijmeni": "Nachname",
  "chyba.prijmeni": "Bitte geben Sie Ihren Nachnamen an",
  "chyba.telefon": "Bitte geben Sie Ihre Telefonnummer an",
  "chyba.platnytelefon": "Bitte geben Sie eine gültige Telefonnummer ein",
  "chyba.email": "Bitte geben Sie Ihre E-Mail-Adresse an",
  "chyba.platnyemail": "Bitte geben Sie eine gültige E-Mail ein",
  "kontakt.text": "Text",
  "chyba.text": "Bitte geben Sie den Text der Nachricht ein",
  "kontakt.souhlas": "Ich bin mit der Verarbeitung",
  "kontakt.zpracovani": "der personenbezogenen Daten einverstanden",
  "chyba.gdpr": "Die Zustimmung zur Verarbeitung personenbezogener Daten ist erforderlich",
  "kontakt.odeslat": "Senden",

  /** Formulář odeslán */
  "formularodeslan.title": "Formular gesendet",
  "formularodeslan.description": "Thermoplastisches Spritzgießen, Rotationsgießen, thermoplastisches Plattengießen.",
  "formularodeslan.nadpis1": "Formular",
  "formularodeslan.nadpis2": "gesendet",
  "formularodeslan.text": "Das Formular wurde erfolgreich abgeschickt, wir werden Sie so schnell wie möglich kontaktieren.",

  /** Ochrana osobních údajů */
  "ochranaosobnichudaju.title": "Schutz der personenbezogenen Daten",
  "ochranaosobnichudaju.description": "Schutz der personenbezogenen Daten der Kunden der Gesellschaft Pastor & syn, s.r.o.",
  "ochranaosobnichudaju.text1": "Schutz der personenbezogenen Daten der Kunden der Gesellschaft {company}",
  "ochranaosobnichudaju.text2": "Datum des Inkrafttretens: 10. Mai 2016",
  "ochranaosobnichudaju.text3": "Umfang und Zweck der Erhebung und Verarbeitung von personenbezogenen Daten:",
  "ochranaosobnichudaju.text4": "Die Firma {company} mit Sitz in Modrá 44, 407 02 Jílové und der Identifikationsnummer 27348652, erhält und verarbeitet Ihre persönlichen Daten aus folgenden Gründen:",
  "ochranaosobnichudaju.text5": "ordnungsgemäße Erbringung der von uns angebotenen Dienstleistungen",
  "ochranaosobnichudaju.text6": "die Möglichkeit, Fragen oder Wünsche zu beantworten",
  "ochranaosobnichudaju.text7": "Verständnis und Klärung Ihrer Bedürfnisse",
  "ochranaosobnichudaju.text8": "ordnungsgemäße Führung unserer internen Bücher",
  "ochranaosobnichudaju.text9": "Zu diesem Zweck erheben wir Ihre persönlichen Daten in den Bereichen: Vorname, Nachname, E-Mail-Adresse und Telefonnummer. Wir speichern diese Daten auf der Grundlage Ihrer Zustimmung. Beim Schutz Ihrer persönlichen Daten halten wir uns an das Gesetz Nr. 101/2000 Sb. über den Schutz personenbezogener Daten.",
  "ochranaosobnichudaju.text10": "Bereitstellung von personenbezogenen Daten durch Kunden",
  "ochranaosobnichudaju.text11": "Wenn Sie unsere Website besuchen, können Sie uns Ihre persönlichen Daten mitteilen, indem Sie eine Online-Anfrage ausfüllen. Wir bitten Sie um die Angabe von Kontaktinformationen (Vorname, Nachname, E-Mail-Adresse und Telefonnummer) und um Ihre Zustimmung zur Verarbeitung Ihrer personenbezogenen Daten. Der Kunde erklärt sich damit einverstanden, dass die genannten personenbezogenen Daten in die elektronische Datenbank von {company} aufgenommen werden und dass sie im Einklang mit dem Gesetz Nr. 101/2000 Sb. über den Schutz personenbezogener Daten in seiner geänderten Fassung und der VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016 zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten und zum freien Datenverkehr erfasst und verarbeitet werden. Wenn Sie nicht möchten, dass wir in Zukunft personenbezogene Daten über Sie sammeln, können Sie Ihre Einwilligung jederzeit per E-Mail an {email} widerrufen.",
  "ochranaosobnichudaju.text12": "Zugang zu Ihren persönlichen Daten",
  "ochranaosobnichudaju.text13": "Sie haben das Recht, uns aufzufordern, Ihnen mitzuteilen, wie viele personenbezogene Daten wir über Sie sammeln. Wenn Sie Interesse haben, können Sie eine Kopie Ihrer persönlichen Daten per E-Mail an {email} anfordern.{br}Sie haben das Recht, jederzeit die Löschung Ihrer personenbezogenen Daten unter der E-Mail-Adresse {email} zu verlangen.",
  "ochranaosobnichudaju.text14": "Informationen in Bezug auf Dritte",
  "ochranaosobnichudaju.text15": "Ohne Ihre Zustimmung werden wir Ihre persönlichen Daten nicht an Dritte weitergeben, um deren Produkte oder Dienstleistungen zu vermarkten.",
  "ochranaosobnichudaju.text16": "Verfahren zum Schutz personenbezogener Daten",
  "ochranaosobnichudaju.text17": "Mit dem Besuch dieser Website erklären Sie sich damit einverstanden, dass wir Ihre personenbezogenen Daten gemäß den oben genannten Bestimmungen erfassen und verarbeiten. Wir haben die Website in geeigneter Weise gesichert, so dass eine unbefugte Weitergabe, Nutzung, Veränderung oder Zerstörung der persönlichen Daten, die Sie uns über die Website zur Verfügung stellen, ausgeschlossen ist. Sollten Sie Fragen zur Sicherheit unserer Website haben, senden Sie diese per E-Mail an {email}.",
  "ochranaosobnichudaju.text18": "Aktualisierung Ihrer Daten",
  "ochranaosobnichudaju.text19": "Sie haben die Möglichkeit, Ihre personenbezogenen Daten zu überprüfen, zu ändern oder deren Löschung zu verlangen, indem Sie eine E-Mail an {email} senden.",
  "ochranaosobnichudaju.text20": "Übermittlung von Daten an andere Länder",
  "ochranaosobnichudaju.text21": "Die Firma {company} übermittelt keine personenbezogenen Daten in andere Länder.",
  "ochranaosobnichudaju.text22": "Schutz der von Kindern übermittelten personenbezogenen Daten",
  "ochranaosobnichudaju.text23": "Diese Website ist für Personen bestimmt, die das 18. Lebensjahr vollendet haben. Unser Unternehmen sammelt nicht wissentlich persönliche Daten von Personen unter 18 Jahren. Sollte es vorkommen, dass wir unbeabsichtigt personenbezogene Daten von einem Website-Besucher erhalten haben, der das 18. Lebensjahr noch nicht vollendet hat, werden wir diese Daten aus unseren Unterlagen löschen. Wenn Sie unter 18 Jahre alt sind, können Sie unsere Website besuchen, aber Sie dürfen uns keine persönliche Daten zur Verfügung stellen. Es ist nicht möglich, sich auf der Website zu registrieren.",
  "ochranaosobnichudaju.text24": "Aktualisierung unserer internen Regelung zum Schutz personenbezogener Daten",
  "ochranaosobnichudaju.text25": "Wir werden diese interne Regelung entsprechend der Änderung der gesetzlichen Vorschriften und des Angebots unserer Dienstleistungen aktualisieren. Wir werden die geänderte Fassung hier veröffentlichen. Das Datum des Inkrafttretens dieser Regelung finden Sie oben auf dieser Seite.",
  "ochranaosobnichudaju.text26": "Zustimmung zur Verarbeitung von personenbezogenen Daten",
  "ochranaosobnichudaju.text27": "Ich bestätige, dass ich durch die Bereitstellung meiner persönlichen Daten an {company} mich damit einverstanden erkläre, dass meine persönlichen Daten für die Zwecke, die sich aus dem Angebotsformular ergeben, auf unbestimmte Zeit verarbeitet werden.",
  "ochranaosobnichudaju.text28": "Ich bestätige ferner, dass ich ordnungsgemäß informiert worden bin:",
  "ochranaosobnichudaju.text29": "über den Umfang und den Zweck der verarbeiteten personenbezogenen Daten",
  "ochranaosobnichudaju.text30": "über mein uneingeschränktes Recht, diese Zustimmung jederzeit zu widerrufen, sowie über das Recht auf Zugang und Änderung meiner personenbezogenen Daten",
  "ochranaosobnichudaju.text31": "über mein Recht, jederzeit Auskunft über die Verarbeitung meiner personenbezogenen Daten zu verlangen",
  "ochranaosobnichudaju.text32": "Passiv bereitgestellte Informationen",
  "ochranaosobnichudaju.text33": "Bei der Navigation auf der Website können bestimmte Informationen passiv gesammelt werden (d.h. ohne dass Sie sie aktiv zur Verfügung stellen), indem verschiedene Technologien eingesetzt werden (z.B. Cookies, Javascript-Messsysteme, insbesondere Google Analytics, und die Standardprotokollierung Ihrer HTTP-Anfragen auf dem Teilserver).",
  "ochranaosobnichudaju.text34": "Ihr Internet-Browser übermittelt automatisch einige der aufgeführten anonymen Informationen an diese Seiten, z.B. die URL des Webportals oder der Suchmaschine, die Sie gerade verlassen haben (sog. Referrer URL), Ihre IP-Adresse, die Version des Browsers und des Betriebssystems, das Ihr Computer gerade verwendet, die Standard-Spracheinstellungen oder Informationen über Ihre Browser-Add-ons. Diese Website erfordert für ihren Betrieb die Speicherung von Cookies in Ihrem Browser. Sie können das System so einstellen, dass Sie informiert werden, wenn ein Cookie gesendet wird, oder Sie können Cookies ablehnen oder ganz deaktivieren, allerdings können bestimmte Teile dieser Website ohne Cookies nicht richtig funktionieren.",

  /** 404 */
  "error.title": "Seite nicht gefunden",
  "error.description": "Thermoplastisches Spritzgießen, Rotationsgießen, thermoplastisches Plattenformen.",
  "error.nadpis1": "Seite",
  "error.nadpis2": "nicht gefunden",
  "error.text": "Seite nicht gefunden."
};
