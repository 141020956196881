import React from "react";
import { Meta, Breadcrumbs, ProductItem, Svg, removeDiacritics } from "modules/layout";
import { Link } from "react-router-dom";
import data from "../data.json";
import { useIntl } from "react-intl";
import { AppRoute } from "const";

export const Produkty: React.FC = () => {
  const { formatMessage, locale } = useIntl();
  const intl = useIntl();

  function localizeRouteKey(path: string) {
    return `/${locale}` + formatMessage({ id: path });
  }

  const breadcrumbs = [
    { label: intl.formatMessage({ id: "produkty.menu" }), link: localizeRouteKey(AppRoute["Produkty"]) },
  ];

  return (
    <>
      <Meta
        clas="body--clip"
        title={intl.formatMessage({ id: "produkty.title" })}
        description={intl.formatMessage({ id: "produkty.description" })}
      />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      {data.product.map((item, idx) => (
        <ProductItem
          key={idx}
          id={idx + 1}
          title={item[`title_${locale}`]}
          text={item[`text_${locale}`]}
          svgs={item.svgs} // Předáme pole SVG
        />
      ))}
      <div className="block padding">
        <div className="container">
          <div className="row margin double">
            <div className="col-md-6 mb sm">
              <Svg src="optimalni-reseni" id="9" />
            </div>
            <div className="col-md-6 align-self-center order-md-first">
              <div className="md-padding pr-md-0">
                <p className="block__subtitle mb">{intl.formatMessage({ id: "produkty.text" })}</p>
                <Link
                  className="button button--blue"
                  to={
                    localizeRouteKey(AppRoute["Kontakt"]) +
                    "#" +
                    removeDiacritics(intl.formatMessage({ id: "kontakt.formular" }))
                  }
                  title={intl.formatMessage({ id: "kontakt.formular" })}
                >
                  {intl.formatMessage({ id: "common.poptat" })}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
