import { Link } from "react-router-dom";
import Logo from "../../../themes/frontend/img/logo/logo.svg";
import { useIntl } from "react-intl";
import { AppRoute } from "const";

export const Footer: React.FC = () => {
  const { formatMessage, locale } = useIntl();
  const intl = useIntl();

  function localizeRouteKey(path: string) {
    return `/${locale}` + formatMessage({ id: path });
  }

  return (
    <>
      <footer className="footer" itemScope="" itemType="http://schema.org/WPFooter">
        <div className="padding">
          <div className="container">
            <div className="row margin double">
              <div className="col-md-4">
                <div className="contact">
                  <div className="contact__logo mb sm">
                    <Link className="contact__link" to={localizeRouteKey(AppRoute["Home"])} title="Pastor & syn, s.r.o.">
                      <img className="contact__img" src={Logo} alt="Pastor & syn, s.r.o." width="1301" height="244" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="row margin">
                  <div className="col-md-4 mb sm">
                    <div className="contact">
                      <div className="contact__title">{intl.formatMessage({ id: "kontakt.adresa" })}</div>
                      <address className="mb-0">Modrá 44, 407 01 Jílové</address>
                    </div>
                  </div>
                  <div className="col-md-4 mb sm">
                    <div className="contact">
                      <div className="contact__title">{intl.formatMessage({ id: "kontakt.telefon" })}</div>
                      <a className="contact__href" href="tel:+420603338915" title="+420 603 338 915">+420 603 338 915</a>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="contact">
                      <div className="contact__title">{intl.formatMessage({ id: "kontakt.email" })}</div>
                      <a className="contact__href" href="mailto:pastorl@pastorasyn.cz" title="pastorl@pastorasyn.cz">pastorl@pastorasyn.cz</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__designer">
          <div className="container">
            <div className="row no-col justify-content-space-between">
              <p className="footer__paragraph footer__paragraph--copyright">&copy; {new Date().getFullYear()} Pastor & syn, s.r.o.</p>
              <p className="footer__paragraph footer__paragraph--designer">{intl.formatMessage({ id: "common.vytvorila" })} {intl.formatMessage({ id: "common.agentura" })}<a className="footer__blank" href="https://core1.agency" title={intl.formatMessage({ id: "common.agentura" })} target="_blank" rel="noreferrer">{intl.formatMessage({ id: "common.agentura" })} core1</a></p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
