import { useEffect } from "react";
import { Link } from "react-router-dom";
import Video from "../themes/frontend/video/master.m3u8";
import data from "../data.json";
import Eu from "../themes/frontend/img/eu.svg";
import { Meta, removeDiacritics, initSmoothScroll, VideoPlayer, Svg, Pictogram, DownloadLink } from "modules/layout";
import { AppRoute } from "const";
import { FormattedMessage, useIntl } from "react-intl";

const repeatedData = Array(3).fill(data.brand).flat();

export const Home: React.FC = () => {
  useEffect(() => {
    initSmoothScroll();
  }, []);

  const { formatMessage, locale } = useIntl();
  const intl = useIntl();

  function localizeRouteKey(path: string) {
    return `/${locale}` + formatMessage({ id: path });
  }

  return (
    <>
      <Meta clas="" title={intl.formatMessage({ id: "home.title" })} description={intl.formatMessage({ id: "home.description" })} />
      <div className="block block--video block--half padding pt-0">
        <div className="relative container container--full">
          <div className="clip clip--green clip--absolute"></div>
          <div className="container container--alt">
            <div className="row">
              <div className="col-md-5 align-self-center">
                <div className="relative clip clip--green clip--sm color--white padding">
                  <h1 className="block__title block__title--margin mb"><FormattedMessage id="home.nadpis" /></h1>
                  <Link className="button button--blue button--top" to={localizeRouteKey(AppRoute["Vyroba"])} title={intl.formatMessage({ id: "vyroba.menu" })}><FormattedMessage id="common.zjistit" /></Link>
                </div>
              </div>
            </div>
          </div>
          {true ? (
            <VideoPlayer src={Video} />
          ) : (
            <video className="video mb sm" autoPlay loop muted playsInline poster="https://cdn.core1.agency/video/pastorasyn.cz/video-homepage.webp">
              <source src="https://cdn.core1.agency/video/pastorasyn.cz/video-homepage.mp4" type="video/mp4"/>
            </video>
          )}
        </div>
        <div className="relative container container--claim">
          <div className="claim">
            <div className="claim__item claim__item--margin">
              <a className="claim__link claim__link--address" href="https://www.google.com/maps/place/Pastor+%26+syn,+s.r.o/@50.7606341,14.0816588,17z/data=!3m1!4b1!4m6!3m5!1s0x47099945f4d69645:0x301a09443e1a639d!8m2!3d50.7606341!4d14.0842337!16s%2Fg%2F11c2j7n4vd?entry=ttu" title="Pastor & syn, s.r.o." target="_blank" rel="noreferrer"><FormattedMessage id="home.nachazime" /></a>
            </div>
            <div className="claim__item">
              <a className="claim__link claim__link--arrow scroll" href={"#"+removeDiacritics(intl.formatMessage({ id: "vyroba.menu" }))} title={intl.formatMessage({ id: "common.dozvedet" })}><FormattedMessage id="common.dozvedet" /></a>
            </div>
          </div>
        </div>
      </div>
      <div id={removeDiacritics(intl.formatMessage({ id: "vyroba.menu" }))} className="block padding">
        <div className="container">
          <div className="row margin double">
            <div className="col-md-6 mb sm">
              <Svg src="termoplasty" id="1" />
            </div>
            <div className="col-md-6 align-self-center">
              <div className="md-padding pr-md-0">
                <p className="block__title mb"><span className="color color--green"><FormattedMessage id="home.vyroba1" /><br className="hidden-sm" /> <FormattedMessage id="home.vyroba2" /></span><br /> <FormattedMessage id="home.vyroba3" /><br className="hidden-sm" /> <FormattedMessage id="home.vyroba4" /></p>
                <p className="block__text mb"><FormattedMessage id="home.vyroba5" /></p>
                <Link className="button button--blue" to={localizeRouteKey(AppRoute["Vyroba"])} title={intl.formatMessage({ id: "vyroba.menu" })}><FormattedMessage id="vyroba.menu" /></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pictogram />
      <div className="block padding">
        <div className="container">
          <div className="row margin double">
            <div className="col-md-6 mb sm">
              <Svg src="plasticke-hmoty" id="2" />
            </div>
            <div className="col-md-6 align-self-center order-md-first">
              <div className="md-padding pl-md-0">
                <p className="block__title mb"><FormattedMessage id="home.historie1" /> <span className="color color--green"><FormattedMessage id="home.historie2" /></span></p>
                <p className="block__text mb"><FormattedMessage id="home.historie3" /></p>
                <Link className="button button--blue" to={localizeRouteKey(AppRoute["Historie"])} title={intl.formatMessage({ id: "historie.menu" })}><FormattedMessage id="historie.menu" /></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block block--brand padding">
        <div className="container">
          <p className="block__text bold mb"><FormattedMessage id="home.partneri" /></p>
          <div className="brand">
            <div className="brand__track">
              {repeatedData.map((_im, idx) => (
                <div className="brand__logo" key={idx}>
                  <img className="brand__img" src={require(`../themes/frontend/img/brand/${removeDiacritics(_im.title)}.webp`).default} alt={_im.title} width={_im.width} height={_im.height} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="block block--half padding">
        <div className="container container--full">
          <div className="eu">
            <div className="container container--alt">
              <div className="row">
                <div className="col-md-8">
                  <div className="eu__flag padding">
                    <img className="eu__img mb sm" src={Eu} alt="Pastor & syn, s.r.o." width="185" height="123" />
                    <p className="eu__text mb sm"><FormattedMessage id="home.projekt" values={{ br: <br /> }} /></p>
                  </div>
                </div>
                <div className="col-md-4 align-self-center">
                  <div className="text-md-center padding">
                    <DownloadLink fileName="snizeni-energeticke-narocnosti.pdf" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
