const baseStrings = {
  /** Routes */
  "routes.home": "/",
  "routes.vyroba": "/vyroba",
  "routes.produkty": "/produkty",
  "routes.historie": "/historie",
  "routes.kontakt": "/kontakt",
  "routes.formularodeslan": "/formular-odeslan",
  "routes.ochranaosobnichudaju": "/ochrana-osobnich-udaju",

  /** Common */
  "common.lang": "cs",
  "common.poptavka": "Poptávka",
  "common.poptat": "Poptat nás",
  "common.zjistit": "Zjistit více",
  "common.dozvedet": "Dozvědět se více",
  "common.vytvorila": "Vytvořila",
  "common.agentura": "Jedničková Digitální agentura",
  "common.vratte": "Vraťte se, prosím, na úvodní stránku.",
  "common.uvod": "Úvodní stránka",

  /** Pictogram */
  "pictogram.nadpis1": "Naše produkty",
  "pictogram.nadpis2": "Zákázková výroba plastů na míru",
  "pictogram.text": "Na zakázku pro Vás vytvoříme cokoliv z plastů ve velkém! S naším týmem zkušených odborníků a moderními technologiemi jsme připraveni realizovat vaše projekty a potřeby.",
  "pictogram.produkty": "Naše produkty",
  "pictogram.button": "Mám zakázku",

  /** Homepage */
  "home.menu": "Úvod",
  "home.title": "Tvarování plastických hmot",
  "home.description": "Vstřikování termoplastů, rotační natavování, tvarování desek z termoplastu.",
  "home.nadpis": "Pokud hledáte spolehlivého partnera pro výrobu plastových výrobků, jste na správném místě.",
  "home.nachazime": "Nacházíme se na kraji Děčína v Jílovém",
  "home.vyroba1": "Vstřikujeme",
  "home.vyroba2": "termoplasty",
  "home.vyroba3": "na moderních",
  "home.vyroba4": "vstřikolisech",
  "home.vyroba5": "Používáme značku Zhafir s uzavírací silou od 40 do 130 tun. V součastné době máme 15 vstřikolisů.",
  "home.historie1": "S tradicí od roku 1946 se specializujeme na",
  "home.historie2": "tvarování plastických hmot",
  "home.historie3": "Máme k dispozici moderní a progresivní technologie.",
  "home.partneri": "Naši významní partneři",
  "home.projekt": "Projekt Pastor a Syn{br}je spolufinancován Evropskou unií",
  "home.stahnout": "Stáhnout PDF",

  /** Výroba */
  "vyroba.menu": "Výroba",
  "vyroba.title": "Výroba plastických hmot",
  "vyroba.description": "Vstřikujeme všechny druhy termoplastů, zaměřujeme se na zakázkovou nástrojařskou výrobu, vyrábíme nádrže o objemu až 600 litrů a zpracováváme desky z termoplastu.",
  "vyroba.nadpis1": "Vstřikování",
  "vyroba.nadpis2": "plastů",
  "vyroba.text1": "Využíváme nejmodernější a progresivní technologie při vstřikování všech druhů termoplastů. Pracujeme na 15 elektrických vstřikovacích strojích značky Zhafir s uzavírací silou od 60 do 120 tun.",
  "vyroba.nadpis3": "Nástrojařská",
  "vyroba.nadpis4": "výroba",
  "vyroba.text2": "Zajišťujeme kompletní servis od návrhu výlisku, konstrukce formy až po vlastní fyzickou realizaci výroby vstřikovacích forem. K samozřejmosti u nás patří i servis a údržba forem zákazníků. Dále se zaměřujeme na zakázkovou nástrojařskou výrobu. Poskytuje konstrukční poradenství v oblasti vstřikovacích forem. Naším cílem je zvýšení efektivity ve výrobě plastových dílů.",
  "vyroba.nadpis5": "Rotační",
  "vyroba.nadpis6": "natavování",
  "vyroba.text3": "V této moderní technologii jsme schopni vyrábět nádrže o objemu až 600 litrů.",
  "vyroba.nadpis7": "Svařování",
  "vyroba.nadpis8": "a tvarování",
  "vyroba.nadpis9": "desek",
  "vyroba.text4": "Věnujeme se převážně zpracováním desek z PP, PE a PVC na septiky, bazény a jiné nádrže dle potřeb zákazníka.",

  /** Produkty */
  "produkty.menu": "Produkty",
  "produkty.title": "Naše výrobky z plastických hmot",
  "produkty.description": "Při výrobě našich produktů používáme moderní a progresivní technologie pro tvarování plastických hmot.",
  "produkty.text": "Jsme otevřeni projednání každého specifického požadavku a rádi se s vámi sejdeme, abychom lépe poznali Vaše potřeby a společně našli optimální řešení.",

  /** Historie */
  "historie.menu": "Historie",
  "historie.title": "Historie zpracování plastu",
  "historie.description": "Historie zpracování plastu má u nás opravdu dlouhou tradici, v roce 1991 došlo k privatizaci podniku.",
  "historie.nadpis": "Jsme tu již od roku 1946 a máme bohatou firemní historii.",
  "historie.text": "Pokud hledáte spolehlivého partnera pro výrobu plastových výrobků, jste na správném místě.",

  /** Kontakt */
  "kontakt.menu": "Kontakt",
  "kontakt.title": "Kontakty Pastor & syn, s r. o.",
  "kontakt.description": "Kontakty Pastor & syn, s r. o. Jílové.",
  "kontakt.trasa": "Trasa",
  "kontakt.nadpis": "Váš spolehlivý partner ve zpracování plastů.",
  "kontakt.podnadpis": "Kontaktujte nás, pokud hledáte spolehlivého partnera a záruku kvality ve výrobě plastových výrobků.",
  "kontakt.ip": "Vaše IP adresa bude zaslána do aplikace Google Maps",
  "kontakt.zobrazit": "Zobrazit mapu",
  "kontakt.najit": "Najít na mapě",
  "kontakt.adresa": "Adresa",
  "kontakt.telefon": "Telefon",
  "kontakt.email": "Email",
  "kontakt.ico": "IČO",
  "kontakt.dic": "DIČ",
  "kontakt.ucet": "Účet",
  "kontakt.osoby": "Kontaktní osoby",
  "kontakt.pozice1": "jednatel společnosti{br}ředitel",
  "kontakt.pozice2": "jednatelka společnosti{br}finanční a personální ředitelka",
  "kontakt.pozice3": "výrobně-obchodní ředitelka",
  "kontakt.formular": "Poptávkový formulář",
  "kontakt.zanechte": "Zanechte nám, prosím, zprávu, náš specialista se jí bude co nejdříve zabývat.",
  "kontakt.jmeno": "Jméno",
  "chyba.jmeno": "Vyplňte, prosím, Vaše jméno",
  "kontakt.prijmeni": "Příjmení",
  "chyba.prijmeni": "Vyplňte, prosím, Vaše příjmení",
  "chyba.telefon": "Vyplňte, prosím, Váš telefon",
  "chyba.platnytelefon": "Vyplňte, prosím, platný telefon",
  "chyba.email": "Vyplňte, prosím, Váš email",
  "chyba.platnyemail": "Vyplňte, prosím, platný email",
  "kontakt.text": "Text",
  "chyba.text": "Vyplňte, prosím, text zprávy",
  "kontakt.souhlas": "Souhlasím se",
  "kontakt.zpracovani": "zpracováním osobních údajů",
  "chyba.gdpr": "Je třeba udělit souhlas se zpracováním osobních údajů",
  "kontakt.odeslat": "Odeslat",

  /** Formulář odeslán */
  "formularodeslan.title": "Formulář odeslán",
  "formularodeslan.description": "Vstřikování termoplastů, rotační natavování, tvarování desek z termoplastu.",
  "formularodeslan.nadpis1": "Formulář",
  "formularodeslan.nadpis2": "odeslán",
  "formularodeslan.text": "Formulář byl úspěšně odeslán, budeme Vás co nejdříve kontaktovat.",

  /** Ochrana osobních údajů */
  "ochranaosobnichudaju.title": "Ochrana osobních údajů",
  "ochranaosobnichudaju.description": "Ochrana osobních údajů klientů společnosti Pastor & syn, s.r.o.",
  "ochranaosobnichudaju.text1": "Ochrana osobních údajů klientů společnosti {company}",
  "ochranaosobnichudaju.text2": "Datum účinnosti od: 10. 5. 2016",
  "ochranaosobnichudaju.text3": "Rozsah a účel shromažďování a zpracování osobních údajů:",
  "ochranaosobnichudaju.text4": "Společnost {company} se sídlem Modrá 44, 407 02 Jílové a identifikačním číslem 27348652, získává a zpracovává Vaše osobní údaje z důvodu:",
  "ochranaosobnichudaju.text5": "správného poskytování služeb, které nabízíme",
  "ochranaosobnichudaju.text6": "možnosti odpovědět na Vaše případné dotazy či požadavky",
  "ochranaosobnichudaju.text7": "pochopení a vyjasnění Vašich potřeb",
  "ochranaosobnichudaju.text8": "správného vedení naší interní evidence",
  "ochranaosobnichudaju.text9": "K tomuto účelu shromažďujeme Vaše osobní údaje v rozsahu: jméno, příjmení, e-mailovou adresu a telefonní číslo. Tyto údaje uchováváme na základě Vámi poskytnutého souhlasu. Při ochraně Vašich osobních údajů se řídíme zákonem č. 101/2000 Sb. o ochraně osobních údajů.",
  "ochranaosobnichudaju.text10": "Poskytnutí osobních údajů klientem",
  "ochranaosobnichudaju.text11": "Při návštěvě našich webových stránek nám můžete poskytnout své osobní údaje vyplněním on-line poptávky. Požádáme Vás o poskytnutí kontaktních údajů (jméno, příjmení, e-mailová adresa a telefonní číslo) a souhlas se zpracováním Vašich osobních údajů. Klient souhlasí s tím, aby uvedené osobní údaje byly zahrnuty do elektronické databáze společnosti {company} a aby byly shromažďovány a zpracovávány v souladu se zákonem č. 101/2000 Sb. o ochraně osobních údajů, v platném znění a NAŘÍZENÍ EVROPSKÉHO PARLAMENTU A RADY (EU) 2016/679 ze dne 27. dubna 2016 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů. Pokud si v budoucnu nepřejete, abychom shromažďovali osobní údaje o Vás, můžete souhlas kdykoliv odvolat odesláním e-mailu na {email}.",
  "ochranaosobnichudaju.text12": "Přístup k Vašim osobním údajům",
  "ochranaosobnichudaju.text13": "Máte právo požádat nás o sdělení rozsahu osobních údajů, které o Vás shromažďujeme. V případě zájmu můžete požádat mailem o kopii Vašich osobních údajů na adrese {email}.{br}Máte právo nás emailem kdykoliv požádat o vymazání Vašich osobních údajů na mailové adrese {email}.",
  "ochranaosobnichudaju.text14": "Informace třetím stranám",
  "ochranaosobnichudaju.text15": "Vaše osobní údaje neposkytneme třetím stranám pro použití při marketingu jejich produktů nebo služeb bez Vašeho souhlasu.",
  "ochranaosobnichudaju.text16": "Způsob ochrany osobních údajů",
  "ochranaosobnichudaju.text17": "Návštěvou této webové stránky nám poskytujete svůj souhlas pro získávání, shromažďování a zpracovávání Vašich osobních údajů podle výše uvedených pravidel. Zabezpečili jsme webové stránky odpovídajícím způsobem tak, aby nedošlo k žádnému nepovolenému sdělení, využití, změně nebo zničení osobních údajů, které nám prostřednictvím stránek poskytnete. Pokud máte dotazy ohledně zabezpečení našich webových stránek, zašlete je e-mailem na adresu {email}.",
  "ochranaosobnichudaju.text18": "Aktualizace Vašich údajů",
  "ochranaosobnichudaju.text19": "Máte možnost své osobní údaje zkontrolovat, změnit nebo požádat o jejich vymazání zasláním e-mailu na adresu {email}.",
  "ochranaosobnichudaju.text20": "Předávání údajů do jiných zemí",
  "ochranaosobnichudaju.text21": "Společnost {company} nepředává osobní údaje do jiných zemí.",
  "ochranaosobnichudaju.text22": "Ochrana osobních údajů dětí",
  "ochranaosobnichudaju.text23": "Tyto stránky jsou určeny osobám, které dosáhli 18 let věku. Naše společnost vědomě osobní údaje jakýchkoliv osob mladších 18-ti let neshromažďuje. Pokud se stane, že jsme neúmyslně obdrželi osobní údaje od návštěvníka stránek, který dosud nedosáhl věku 18 let, vymažeme tyto údaje z našich záznamů. Pokud jste ještě nedosáhli věku 18 let, můžete si naše webové stránky prohlížet, ale nesmíte nám poskytovat osobní údaje. Není možné, abyste se na stránkách zaregistrovali.",
  "ochranaosobnichudaju.text24": "Aktualizace našeho vnitřního předpisu o ochraně osobních údajů",
  "ochranaosobnichudaju.text25": "Podle změny právních předpisů a nabídky našich služeb, budeme tento interní předpis aktualizovat. Zde zveřejníme jeho upravené znění. Datum účinnosti tohoto předpisu naleznete na začátku této stránky.",
  "ochranaosobnichudaju.text26": "Souhlas se zpracováním osobních údajů",
  "ochranaosobnichudaju.text27": "Potvrzuji, že poskytnutím svých osobních údajů společnosti {company} uděluji svůj souhlas se zpracováním svých osobních údajů na dobu neurčitou pro účely které vyplývají z nabídkového formuláře.",
  "ochranaosobnichudaju.text28": "Dále potvrzuji, že jsem byl/a řádně informován/a:",
  "ochranaosobnichudaju.text29": "o rozsahu a účelu zpracovávaných osobních údajů",
  "ochranaosobnichudaju.text30": "o svém nepodmíněném právu kdykoliv tento svůj souhlas odvolat, jakož i o právu získat přístup k údajům o mé osobě a změnit je",
  "ochranaosobnichudaju.text31": "o svém právu požadovat kdykoliv informace o zpracovávání mých osobních údajů",
  "ochranaosobnichudaju.text32": "Pasivně poskytované informace",
  "ochranaosobnichudaju.text33": "Při pohybu na webové prezentaci mohou být jisté informace pasivně shromažďovány (tj. získány, aniž by byly z Vaší strany aktivně poskytnuty) za použití různých technologií (jako například cookies, javascriptové měřicí systémy, zejména Google Analytics, a standardní logování Vašich HTTP požadavků na straně serveru).",
  "ochranaosobnichudaju.text34": "Váš internetový prohlížeč (browser) automaticky předává těmto stránkám některé z uvedených anonymních informací, např. URL webového portálu či vyhledávače, který jste právě opustil/a (tzv. Referrer URL), vaši IP adresu, verzi browseru a operačního systému, který Váš počítač momentálně používá, výchozí nastavení jazyka, případně informace o nainstalovaných doplňcích Vašeho prohlížeče. Tyto stránky pro svůj provoz vyžadují ukládání cookies ve vašem prohlížeči. Můžete jej nastavit tak, aby Vás informoval v okamžiku, kdy je cookie zaslána, anebo cookies zcela odmítnout či zakázat, nicméně určité části těchto webových stránek nemusí bez cookies správně fungovat.",

  /** 404 */
  "error.title": "Stránka nenalezena",
  "error.description": "Vstřikování termoplastů, rotační natavování, tvarování desek z termoplastu.",
  "error.nadpis1": "Stránka",
  "error.nadpis2": "nenalezena",
  "error.text": "Stránka nebyla nalezena."
};

export type LanguageStrings = typeof baseStrings;
export const cs = baseStrings;
