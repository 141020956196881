import React, { useState, useEffect } from "react";
import { Svg } from "modules/layout";

export const ProductItem: React.FC<{
  id: number;
  title: string;
  text: string;
  svgs: string[];
}> = ({ id, title, text, svgs }) => {
  const [currentSvg, setCurrentSvg] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSvg((prev) => (prev + 1) % svgs.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [svgs.length]);

  return (
    <div className="block padding">
      <div className="container">
        <div className="row margin double">
          <div className="col-md-6 mb sm">
            <div className="relative">
              {svgs.map((src, index) => (
                <Svg
                  key={index}
                  src={src}
                  id={`${id}-${index}`}
                  clas={index === currentSvg ? "svg__img--active" : ""}
                />
              ))}
            </div>
          </div>
          <div
            className={`col-md-6 align-self-center ${
              id % 2 === 0 ? "order-md-first" : ""
            }`}
          >
            <div className="md-padding pr-md-0">
              <h1 className="block__subtitle mb">{title}</h1>
              <p className="block__small">{text}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
