import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";

export const Meta: React.FC<{clas?:string, title:string, description:string}> = ({ clas, title, description }) => {
  const intl = useIntl();

  useEffect(() => {
    function fetchBusinesses() {
      window.scrollTo(0, 0);

      document.querySelectorAll("meta[name*='title'], meta[property*='title']").forEach(anchor => {
        anchor.setAttribute("content", `${title} | Pastor & syn, s.r.o.`);
      });
      document.querySelectorAll("meta[name*='description'], meta[property*='description']").forEach(anchor => {
        anchor.setAttribute("content", `${description}`);
      });
      document.querySelectorAll("meta[property='og:url']").forEach(anchor => {
        anchor.setAttribute("content", window.location.href);
      });
      document.querySelectorAll("link[rel='canonical']").forEach(anchor => {
        anchor.setAttribute("href", window.location.href);
      });
    }
    fetchBusinesses();
  }, [title, description]);

  return (
    <Helmet>
      <html lang={intl.formatMessage({ id: "common.lang" })} />
      <title>{title} | Pastor & syn, s.r.o.</title>
      <body className={"body " + clas} />
    </Helmet>
  );
};
