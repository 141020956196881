import { FormattedMessage, useIntl } from "react-intl";
import { Meta, Breadcrumbs, obfuscateEmail } from "modules/layout";
import { AppRoute } from "const";

export const OchranaOsobnichUdaju: React.FC = () => {
  const { formatMessage, locale } = useIntl();
  const intl = useIntl();

  function localizeRouteKey(path: string) {
    return `/${locale}` + formatMessage({ id: path });
  }

  const breadcrumbs = [
    { label: intl.formatMessage({ id: "ochranaosobnichudaju.title" }), link: localizeRouteKey(AppRoute["OchranaOsobnichUdaju"]) }
  ];

  return (
    <>
      <Meta clas="" title={intl.formatMessage({ id: "ochranaosobnichudaju.title" })} description={intl.formatMessage({ id: "ochranaosobnichudaju.description" })} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className="block padding">
        <h1><FormattedMessage id="ochranaosobnichudaju.text1" values={{ company: <span className="nowrap">Pastor & syn, s.r.o.</span> }} /></h1>
        <p className="margin-top">{intl.formatMessage({ id: "ochranaosobnichudaju.text2" })}</p>
        <p>{intl.formatMessage({ id: "ochranaosobnichudaju.text3" })}</p>
        <p><FormattedMessage id="ochranaosobnichudaju.text4" values={{ company: <span className="nowrap">Pastor & syn, s.r.o.</span> }} /></p>
        <ul>
          <li>{intl.formatMessage({ id: "ochranaosobnichudaju.text5" })}</li>
          <li>{intl.formatMessage({ id: "ochranaosobnichudaju.text6" })}</li>
          <li>{intl.formatMessage({ id: "ochranaosobnichudaju.text7" })}</li>
          <li>{intl.formatMessage({ id: "ochranaosobnichudaju.text8" })}</li>
        </ul>
        <p>{intl.formatMessage({ id: "ochranaosobnichudaju.text9" })}</p>
        <h2>{intl.formatMessage({ id: "ochranaosobnichudaju.text10" })}</h2>
        <p><FormattedMessage id="ochranaosobnichudaju.text11" values={{ company: <span className="nowrap">Pastor & syn, s.r.o.</span>, email: <a className="color color--link" href={`mailto:${obfuscateEmail("firma@pastorasyn.cz")}`} title={obfuscateEmail("firma@pastorasyn.cz")}>{obfuscateEmail("firma@pastorasyn.cz")}</a> }} /></p>
        <h2>{intl.formatMessage({ id: "ochranaosobnichudaju.text12" })}</h2>
        <p><FormattedMessage id="ochranaosobnichudaju.text13" values={{ email: <a className="color color--link" href={`mailto:${obfuscateEmail("firma@pastorasyn.cz")}`} title={obfuscateEmail("firma@pastorasyn.cz")}>{obfuscateEmail("firma@pastorasyn.cz")}</a>, br: <br /> }} /></p>
        <h2>{intl.formatMessage({ id: "ochranaosobnichudaju.text14" })}</h2>
        <p>{intl.formatMessage({ id: "ochranaosobnichudaju.text15" })}</p>
        <h2>{intl.formatMessage({ id: "ochranaosobnichudaju.text16" })}</h2>
        <p><FormattedMessage id="ochranaosobnichudaju.text17" values={{ email: <a className="color color--link" href={`mailto:${obfuscateEmail("firma@pastorasyn.cz")}`} title={obfuscateEmail("firma@pastorasyn.cz")}>{obfuscateEmail("firma@pastorasyn.cz")}</a> }} /></p>
        <h2>{intl.formatMessage({ id: "ochranaosobnichudaju.text18" })}</h2>
        <p><FormattedMessage id="ochranaosobnichudaju.text19" values={{ email: <a className="color color--link" href={`mailto:${obfuscateEmail("firma@pastorasyn.cz")}`} title={obfuscateEmail("firma@pastorasyn.cz")}>{obfuscateEmail("firma@pastorasyn.cz")}</a> }} /></p>
        <h2>{intl.formatMessage({ id: "ochranaosobnichudaju.text20" })}</h2>
        <p><FormattedMessage id="ochranaosobnichudaju.text21" values={{ company: <span className="nowrap">Pastor & syn, s.r.o.</span> }} /></p>
        <h2>{intl.formatMessage({ id: "ochranaosobnichudaju.text22" })}</h2>
        <p>{intl.formatMessage({ id: "ochranaosobnichudaju.text23" })}</p>
        <h2>{intl.formatMessage({ id: "ochranaosobnichudaju.text24" })}</h2>
        <p>{intl.formatMessage({ id: "ochranaosobnichudaju.text25" })}</p>
        <h2>{intl.formatMessage({ id: "ochranaosobnichudaju.text26" })}</h2>
        <p><FormattedMessage id="ochranaosobnichudaju.text27" values={{ company: <span className="nowrap">Pastor & syn, s.r.o.</span> }} /></p>
        <p>{intl.formatMessage({ id: "ochranaosobnichudaju.text28" })}</p>
        <ul>
          <li>{intl.formatMessage({ id: "ochranaosobnichudaju.text29" })}</li>
          <li>{intl.formatMessage({ id: "ochranaosobnichudaju.text30" })}</li>
          <li>{intl.formatMessage({ id: "ochranaosobnichudaju.text31" })}</li>
        </ul>
        <h2>{intl.formatMessage({ id: "ochranaosobnichudaju.text32" })}</h2>
        <p>{intl.formatMessage({ id: "ochranaosobnichudaju.text33" })}</p>
        <p>{intl.formatMessage({ id: "ochranaosobnichudaju.text34" })}</p>
      </div>
    </>
  );
}
