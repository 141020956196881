import Cookies from "js-cookie";
import { BrowserRouter, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { AppRoute, AppLanguage } from "const";
import * as views from "views";
import { AppLayout } from "modules/layout";
import { LocalizedRouter, LocalizedSwitch, appStrings } from "modules/i18n";

export const App: React.FC = () => {
  var myResizeFunction = function() {
    var scroller = document.documentElement;
    requestAnimationFrame(()=>{
      scroller.style
        .setProperty(
          "--scrollbar-width",
          window.innerWidth - scroller.clientWidth + "px"
        );
    });
  };

  myResizeFunction()

  document.documentElement.className=document.documentElement.className.replace("no-js","js");

  const cookieValue = Cookies.get("formSubmitted");
  if (cookieValue === "1") {
    Cookies.set("formSubmitted", "0");
  }

  return (
    <HelmetProvider>
      <LocalizedRouter
        RouterComponent={BrowserRouter}
        languages={AppLanguage}
        appStrings={appStrings}
      >
        <AppLayout>
          <LocalizedSwitch>
            <Route exact path={AppRoute.Home}>
              <views.Home />
            </Route>
            <Route exact path={AppRoute.Vyroba}>
              <views.Vyroba />
            </Route>
            <Route exact path={AppRoute.Produkty}>
              <views.Produkty />
            </Route>
            <Route exact path={AppRoute.Historie}>
              <views.Historie />
            </Route>
            <Route exact path={AppRoute.Kontakt}>
              <views.Kontakt />
            </Route>
            <Route exact path={AppRoute.FormularOdeslan}>
              <views.FormularOdeslan />
            </Route>
            <Route exact path={AppRoute.OchranaOsobnichUdaju}>
              <views.OchranaOsobnichUdaju />
            </Route>
            <Route path="*">
              <views.GeneralError />
            </Route>
          </LocalizedSwitch>
        </AppLayout>
      </LocalizedRouter>
    </HelmetProvider>
  );
};
