import { Meta, Pictogram, Breadcrumbs, Svg } from "modules/layout";
import { useIntl } from "react-intl";
import { AppRoute } from "const";

export const Vyroba: React.FC = () => {
  const { formatMessage, locale } = useIntl();
  const intl = useIntl();

  function localizeRouteKey(path: string) {
    return `/${locale}` + formatMessage({ id: path });
  }

  const breadcrumbs = [
    { label: intl.formatMessage({ id: "vyroba.menu" }), link: localizeRouteKey(AppRoute["Vyroba"]) }
  ];

  return (
    <>
      <Meta clas="body--clip" title={intl.formatMessage({ id: "vyroba.title" })} description={intl.formatMessage({ id: "vyroba.description" })} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className="block padding">
        <div className="container">
          <h1 className="sr-only">{intl.formatMessage({ id: "vyroba.title" })}</h1>
          <div className="row margin double">
            <div className="col-md-6 mb sm">
              <Svg src="vstrikovani-plastu" id="3" />
            </div>
            <div className="col-md-6 align-self-center">
              <div className="md-padding pr-md-0">
                <h2 className="block__title mb"><span className="color color--green">{intl.formatMessage({ id: "vyroba.nadpis1" })}<br className="hidden-sm" /> {intl.formatMessage({ id: "vyroba.nadpis2" })}</span></h2>
                <p className="block__text mb">{intl.formatMessage({ id: "vyroba.text1" })}</p>
                <p className="block__text bold"><span className="color color--green">01</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block padding">
        <div className="container">
          <div className="row margin double">
            <div className="col-md-6 mb sm">
              <Svg src="nastrojarska-vyroba" id="4" />
            </div>
            <div className="col-md-6 align-self-center order-md-first">
              <div className="md-padding pl-md-0">
                <h2 className="block__title mb"><span className="color color--green">{intl.formatMessage({ id: "vyroba.nadpis3" })}<br className="hidden-sm" /> {intl.formatMessage({ id: "vyroba.nadpis4" })}</span></h2>
                <p className="block__text mb">{intl.formatMessage({ id: "vyroba.text2" })}</p>
                <p className="block__text bold"><span className="color color--green">02</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block padding">
        <div className="container">
          <div className="row margin double">
            <div className="col-md-6 mb sm">
              <Svg src="rotacni-natavovani" id="5" />
            </div>
            <div className="col-md-6 align-self-center">
              <div className="md-padding pr-md-0">
                <h2 className="block__title mb"><span className="color color--green">{intl.formatMessage({ id: "vyroba.nadpis5" })}<br className="hidden-sm" /> {intl.formatMessage({ id: "vyroba.nadpis6" })}</span></h2>
                <p className="block__text mb">{intl.formatMessage({ id: "vyroba.text3" })}</p>
                <p className="block__text bold"><span className="color color--green">03</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block padding">
        <div className="container">
          <div className="row margin double">
            <div className="col-md-6 mb sm">
              <Svg src="svarovani-a-tvarovani-desek" id="6" />
            </div>
            <div className="col-md-6 align-self-center order-md-first">
              <div className="md-padding pl-md-0">
                <h2 className="block__title mb"><span className="color color--green">{intl.formatMessage({ id: "vyroba.nadpis7" })}<br className="hidden-sm" /> {intl.formatMessage({ id: "vyroba.nadpis8" })}<br className="hidden-sm" /> {intl.formatMessage({ id: "vyroba.nadpis9" })}</span></h2>
                <p className="block__text mb">{intl.formatMessage({ id: "vyroba.text4" })}</p>
                <p className="block__text bold"><span className="color color--green">04</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pictogram />
    </>
  );
};
