import { Navigation } from "modules/navigation";
import { Footer } from "modules/layout";

export const AppLayout: React.FC = ({ children }) => {
  return (
    <>
      <Navigation />
      <main className="main">
        {children}
      </main>
      <Footer />
    </>
  );
};
