import { useEffect } from "react";
import { initSmoothScroll, removeDiacritics, Meta, Breadcrumbs, Image, HistoryItem } from "modules/layout";
import { Link } from "react-router-dom";
import data from "../data.json";
import { useIntl } from "react-intl";
import { AppRoute } from "const";

export const Historie: React.FC = () => {
  useEffect(() => {
    initSmoothScroll();
  }, []);

  const { formatMessage, locale } = useIntl();
  const intl = useIntl();

  function localizeRouteKey(path: string) {
    return `/${locale}` + formatMessage({ id: path });
  }

  const breadcrumbs = [
    { label: intl.formatMessage({ id: "historie.menu" }), link: localizeRouteKey(AppRoute["Historie"]) }
  ];

  return (
    <>
      <Meta clas="" title={intl.formatMessage({ id: "historie.title" })} description={intl.formatMessage({ id: "historie.description" })} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className="block block--video block--half padding pt-0">
        <div className="relative container container--full">
          <div className="clip clip--green clip--absolute"></div>
          <div className="container container--alt">
            <div className="row">
              <div className="col-md-5 align-self-center">
                <div className="relative clip clip--green clip--sm color--white padding">
                  <h1 className="block__title block__title--margin mb">{intl.formatMessage({ id: "historie.nadpis" })}</h1>
                  <p className="block__small mb">{intl.formatMessage({ id: "historie.text" })}</p>
                  <Link className="button button--blue button--top" to={localizeRouteKey(AppRoute["Kontakt"])+"#"+removeDiacritics(intl.formatMessage({ id: "kontakt.formular" }))} title={intl.formatMessage({ id: "kontakt.formular" })}>{intl.formatMessage({ id: "common.poptat" })}</Link>
                </div>
              </div>
            </div>
          </div>
          <figure className="figure figure--video">
            <Image src="historie" alt="Pastor & syn, s.r.o." width={1920} height={1080} />
          </figure>
        </div>
        <div className="relative container container--claim hidden-sm">
          <div className="claim">
            <div className="claim__item">
              <a className="claim__link claim__link--arrow scroll" href={"#"+removeDiacritics(intl.formatMessage({ id: "historie.menu" }))} title={intl.formatMessage({ id: "common.dozvedet" })}>{intl.formatMessage({ id: "common.dozvedet" })}</a>
            </div>
          </div>
        </div>
      </div>
      <div id={removeDiacritics(intl.formatMessage({ id: "historie.menu" }))} className="block padding">
        <div className="container">
          <div className="history">
            {data.history.map((item, idx) => (
              <HistoryItem
                key={idx}
                id={`history__item-${idx + 1}`}
                year={item.year}
                text={item[`text_${locale}`]}
                isFirst={idx === 0}
              />
            ))}
            <div className="history__dot"></div>
          </div>
        </div>
      </div>
    </>
  );
};
