import { useState, useEffect } from "react";
import { useIntl } from "react-intl";

export const DownloadLink: React.FC<{fileName: string}> = ({ fileName }) => {
  const [fileUrl, setFileUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const loadFile = async () => {
      try {
        const file = await import(`../../../storage/${fileName}`);
        setFileUrl(file.default);
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    loadFile();
  }, [fileName]);

  if (loading) {
    //return <p>Načítání...</p>;
  }

  if (error) {
    //return <p>Soubor {fileName} nebyl nalezen.</p>;
  }

  const intl = useIntl();

  return (
    <a className="button button--green button--download" href={fileUrl} title={intl.formatMessage({ id: "home.stahnout" })} target="_blank" rel="noreferrer">{intl.formatMessage({ id: "home.stahnout" })}</a>
  );
};
