export enum AppRoute {
  Home = "routes.home",
  Vyroba = "routes.vyroba",
  Produkty = "routes.produkty",
  Historie = "routes.historie",
  Kontakt = "routes.kontakt",
  FormularOdeslan = "routes.formularodeslan",
  OchranaOsobnichUdaju = "routes.ochranaosobnichudaju"
}

export const AppRouteTitles = new Map([
  [AppRoute.Vyroba, "vyroba.menu"],
  [AppRoute.Produkty, "produkty.menu"],
  [AppRoute.Historie, "historie.menu"],
  [AppRoute.Kontakt, "kontakt.menu"]
]);
