import { useState, useEffect } from "react";
import { initSmoothScroll, removeDiacritics } from "modules/layout";
import { useLocation, Link, NavLink } from "react-router-dom";
import Logo from "../../../themes/frontend/img/logo/logo-white.svg";
import { AppLanguage, AppRoute, AppRouteTitles } from "const";
import { FormattedMessage, useIntl } from "react-intl";
import { appStrings } from "../../../modules/i18n";

export const Navigation: React.FC = () => {
  useEffect(() => {
    initSmoothScroll();
  }, []);

  const [open, setOpen] = useState(false);
  const menuToggle = () => {
    open ? setOpen(false) : setOpen(true);
    open ? document.body.classList.remove("body--overflow") : document.body.classList.add("body--overflow");
	};

  const { pathname } = useLocation();
  const { messages } = useIntl();

  const { formatMessage, locale } = useIntl();
  const intl = useIntl();

  function localizeRouteKey(path: string) {
    return `/${locale}` + formatMessage({ id: path });
  }

  function getMatchingRoute(language: string) {
    /**
     * Get the key of the route the user is currently on
     */
    const route = pathname.substring(3); // remove local part "/en" from the pathname /en/contact
    const routeKey = Object.keys(messages).find(key => messages[key] === route);

    /**
     * Find the matching route for the new language
     */
    const matchingRoute = appStrings[language][routeKey];

    /**
     * Return localized route
     */
    return `/${language}` + matchingRoute;
  }

  return (
    <>
      <header className="header padding" itemScope="" itemType="http://schema.org/WPHeader">
        <nav className={`nav ${ open ? "nav--after" : "" }`} itemScope="" itemType="http://schema.org/SiteNavigationElement">
          <div className="logo">
            <Link className="logo__link" to={localizeRouteKey(AppRoute["Home"])} title="Pastor & syn, s.r.o." onClick={() => setOpen(false)}>
              <img className="logo__img" src={Logo} alt="Pastor & syn, s.r.o." width="1301" height="244" />
            </Link>
          </div>
          <span className="dash md-hidden" onClick={() => menuToggle()}>
            <span className={`dash__line dash__line--margin ${ open ? "dash__line--plus" : "" }`}></span>
            <span className={`dash__line dash__line--margin  dash__line--short ${ open ? "dash__line--zero" : "" }`}></span>
            <span className={`dash__line ${ open ? "dash__line--minus" : "" }`}></span>
          </span>
          <div className={`menu ${ open ? "menu--open" : "" }`}>
            <ul className="menu__list">
              {Object.keys(AppRoute).filter((elem) => AppRouteTitles.has(AppRoute[elem])).map((elem) => (
                <li className="menu__item" key={elem}>
                  <NavLink
                    exact
                    className="menu__link"
                    activeClassName="menu__link--active"
                    to={localizeRouteKey(AppRoute[elem])}
                    title={formatMessage({ id: AppRouteTitles.get(AppRoute[elem]) || "" })}
                    onClick={() => setOpen(false)}
                  >
                    {formatMessage({ id: AppRouteTitles.get(AppRoute[elem]) || "" })}
                  </NavLink>
                </li>
              ))}
            </ul>
            <hr className="hr md-hidden" />
            <div className="margin-top md-hidden">
              <div className="lang">
                <ul className="lang__list">
                  {Object.keys(AppLanguage).map((lang) => (
                    <li className={`lang__item ${locale === AppLanguage[lang] ? "lang__item--active" : ""}`} key={lang}>
                      <NavLink
                        className="lang__link"
                        to={getMatchingRoute(AppLanguage[lang])}
                        title={AppLanguage[lang]}
                        onClick={() => setOpen(false)}
                      >
                        {AppLanguage[lang] === "cs" ? "cz" : AppLanguage[lang]}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
              {pathname === localizeRouteKey(AppRoute["Kontakt"]) ?
                <a className="button button--green button--inquiry scroll mt mb" href={"#"+removeDiacritics(intl.formatMessage({ id: "kontakt.formular" }))} title={intl.formatMessage({ id: "common.poptavka" })} onClick={() => {setOpen(false); document.body.classList.remove("body--overflow");}}><FormattedMessage id="common.poptavka" /></a>
              :
                <Link className="button button--green button--inquiry mt mb" to={localizeRouteKey(AppRoute["Kontakt"])+"#"+removeDiacritics(intl.formatMessage({ id: "kontakt.formular" }))} title={intl.formatMessage({ id: "common.poptavka" })} onClick={() => setOpen(false)}><FormattedMessage id="common.poptavka" /></Link>
              }
              <div className="menu__bottom">
                <div className="row no-col justify-content-space-between">
                  <div className="menu__contact">
                    <div className="menu__title"><FormattedMessage id="kontakt.telefon" /></div>
                    <a className="menu__href" href="tel:+420739110076" title="+420 739 110 076">+420 739 110 076</a>
                  </div>
                  <div className="menu__contact">
                    <div className="menu__title"><FormattedMessage id="kontakt.email" /></div>
                    <a className="menu__href" href="mailto:firma@pastorasyn.cz" title="firma@pastorasyn.cz">firma@pastorasyn.cz</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="menu__right hidden-sm">
            {pathname === localizeRouteKey(AppRoute["Kontakt"]) ?
              <a className="button button--green button--inquiry scroll hidden-sm" href={"#"+removeDiacritics(intl.formatMessage({ id: "kontakt.formular" }))} title={intl.formatMessage({ id: "common.poptavka" })}><FormattedMessage id="common.poptavka" /></a>
            :
              <Link className="button button--green button--inquiry hidden-sm" to={localizeRouteKey(AppRoute["Kontakt"])+"#"+removeDiacritics(intl.formatMessage({ id: "kontakt.formular" }))} title={intl.formatMessage({ id: "common.poptavka" })}><FormattedMessage id="common.poptavka" /></Link>
            }
            <div className="lang">
              <ul className="lang__list">
                {Object.keys(AppLanguage).map((lang) => (
                  <li className={`lang__item ${locale === AppLanguage[lang] ? "lang__item--active" : ""}`} key={lang}>
                    <NavLink
                      className="lang__link"
                      to={getMatchingRoute(AppLanguage[lang])}
                      title={AppLanguage[lang]}
                    >
                      {AppLanguage[lang] === "cs" ? "cz" : AppLanguage[lang]}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};
