import { useRef, useState, useEffect } from "react";
import useOnScreen from "./utils";

export const HistoryItem: React.FC<{id: number, year: number, text: string, isFirst?: boolean}> = ({ id, year, text, isFirst = false }) => {
  const ref = useRef(null);
  const [rootMargin, setRootMargin] = useState("0px");
  const [isScrolledPast, setIsScrolledPast] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const height = ref.current.offsetHeight;
      setRootMargin(`0px 0px -${height}px 0px`);
    }
  }, [ref]);

  const isVisible = useOnScreen(ref, rootMargin);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        if (window.scrollY > rect.top + window.pageYOffset) {
          setIsScrolledPast(true);
        } else {
          setIsScrolledPast(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [ref]);

  return (
    <div
      id={id}
      ref={ref}
      className={`history__item ${isFirst ? "" : "margin-top"} ${(isVisible || isScrolledPast) ? "history__item--visible" : ""}`}
    >
      <div className="history__year padding"><span className="history__span">{year}</span></div>
      <div className="history__text padding">{text}</div>
    </div>
  );
};
